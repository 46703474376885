import { BaseAggregatesTest } from 'modules/aggregates/models/base-aggregates-test';

export class AggregatesLabTest extends BaseAggregatesTest {
    id                      = null;
    application_id          = null;
    wc_sample_collection_id = null;
    mc_sample_collection_id = null;
    usage_id                = null;
    min_dimension           = null;
    max_dimension           = null;
    mixture_id              = null;
    cement                  = null;
    cement_type             = null;
    material_number         = null;
    material_designation    = null;
    stock_number            = null;
    stock_designation       = null;
    test_sample_type_id     = null;
    usage_text              = null;
    sample_is_ttmc          = null;
}
