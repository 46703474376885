import { DialogService }                      from 'aurelia-dialog';
import { bindable, customElement, inject }    from 'aurelia-framework';
import { PLATFORM }                           from 'aurelia-pal';
import { BaseViewModel }                      from 'base-view-model';
import { AttachZipSchema }                    from 'modules/aggregates/selected-bulletins/attach-zip-schema';
import { EditSelectedBulletin }               from 'modules/aggregates/selected-bulletins/edit';
import { SelectedBulletinsRepository }        from 'modules/aggregates/selected-bulletins/services/repository';
import { SelectedBulletinZipFilesRepository } from 'modules/aggregates/selected-bulletins/services/zip-files-repository';
import { TestsQuantitySchema }                from 'modules/aggregates/selected-bulletins/tests-quantity-schema';
import { AppContainer }                       from 'resources/services/app-container';

@customElement('selected-bulletins')
@inject(AppContainer, DialogService, SelectedBulletinsRepository, SelectedBulletinZipFilesRepository, AttachZipSchema, TestsQuantitySchema)
export class SelectedBulletins extends BaseViewModel {

    listingId = 'test-selected-bulletins-listing';

    @bindable headerTitle = null;
    @bindable repository;
    @bindable alert;
    @bindable datatable;

    records;
    maxTestsQuantity = 0;

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogService
     * @param selectedBulletinsRepository
     * @param zipFilesRepository
     * @param zipSchema
     * @param testsQuantitySchema
     */
    constructor(appContainer, dialogService, selectedBulletinsRepository, zipFilesRepository, zipSchema, testsQuantitySchema) {
        super(appContainer);

        this.dialogService       = dialogService;
        this.repository          = selectedBulletinsRepository;
        this.zipFilesRepository  = zipFilesRepository;
        this.self                = this;
        this.zipSchema           = zipSchema;
        this.testsQuantitySchema = testsQuantitySchema;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'test-selected-bulletins.manage',
            'test-selected-bulletins.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(model) {
        super.activate();

        this.completeModel = model.completeModel;
        this.records       = model.records ?? [];
        this.isZip         = model.isZip ?? true;

        this.initializeModel();

        if (this.isZip) {
            this.schema = this.zipSchema.schema(this);
        } else {
            if (!this.maxTestsQuantity)
                this.maxTestsQuantity = this.completeModel.max_tests_quantity || 0;

            this.schema = this.testsQuantitySchema.schema(this);
        }

        if (!this.modalJustClosed && !this.records)
            this.fetchData(null);

        this.modalJustClosed = false;

        this.defineDatatable();
    }

    initializeModel() {
        this.model        = this.completeModel;
        this.initialModel = this.completeModel;
    }

    async selectedBulletinsChanged() {
        this.alert = null;
        this.hideDatatable(false);

        await this.fetchData(null);

        this.appContainer.eventAggregator.publish('datatable-must-be-reloaded', { listingId: this.listingId });

        return this.maxTestsQuantity;
    }

    async fetchData(params) {

        let selectedBulletins = this.completeModel.selected_bulletins_ids
            ? this.completeModel.selected_bulletins_ids
            : [this.completeModel.selected_bulletins_id];

        return this.repository.getModels({
            bulletin_ids:   selectedBulletins,
            records:        this.records ?? null,
            test_header_id: this.completeModel.test_header_id ?? null,
            date:           this.completeModel.created_at ?? null,
        }).then(response => {
            this.records                          = response;
            this.completeModel.selected_bulletins = this.records;

            if (typeof this.records === 'undefined' || this.records.length === 0) {
                this.records = [];

                this.hideDatatable();
                this.alert = this.warningAlertMessage(this.appContainer.i18n.tr('form.message.please-select-bulletins-first'));
            } else if (!this.isZip) {
                this.maxTestsQuantity = this.records[0].tests_quantity;
            }
        });
    }

    async submit() {

        this.alert = null;

        if (!this.allRequiredFieldsAreFilled()) {
            return Promise.resolve([]);
        } else {
            await this.updateModel();
            this.initialModel.assign(this.model);

            this.appContainer.notifier.successNotice(this.appContainer.i18n.tr('form.message.success.zip-upload-succeeded'));

            this.hideDatatable();
        }

    }

    /**
     * Button "Cancel" is only shown when this.isZip === true
     * @returns {Promise<void>}
     */
    async cancelZip() {

        this.alert = null;

        // Only show confirmation if zip file was already confirmed
        if (this.completeModel.zip_file_id) {
            if (confirm(this.appContainer.i18n.tr('form.message.remove-uploaded-zip-file'))) {
                this.model.zip_file_id = null;
                this.updateModel();
                this.initialModel.assign(this.model);
                this.zipSchemaVisible = false;
                this.hideDatatable(false);
            }
        } else {
            this.zipSchemaVisible = false;
            this.hideDatatable(false);
        }
    }

    updateModel() {
        this.completeModel.zip_file_id = this.model.zip_file_id;
    }

    /**
     * Check in the front-end if all required fields are filled
     * @returns {boolean}
     */
    allRequiredFieldsAreFilled() {
        let message = '';

        if (!this.model.zip_file_id) {
            message += this.getDescriptive('form.field.file');
        }

        if (message.length > 0) {
            this.alert = this.dangerAlertMessage(
                this.appContainer.i18n.tr('messages.action.failed'),
                message,
            );
        }

        return message.length === 0;

    }

    /**
     * Returns descriptive
     *
     * @param slug
     *
     * @returns String
     */
    getDescriptive(slug) {
        return '<li>' + this.appContainer.i18n.tr('validation.required', {
            attribute: this.appContainer.i18n.tr(slug),
        }) + '</li>';
    }

    /**
     * Defines datatable
     */
    defineDatatable() {
        this.datatable = {
            repository:         {
                search: this.search.bind(this),
            },
            edit:               {
                action: (row, index) => this.openModal({ records: this.records, row: row, index: index }, EditSelectedBulletin),
            },
            destroySelected:    false,
            selectable:         false,
            showResultsPerPage: false,
            buttons:            [
                {
                    label:            'form.button.attach-zip',
                    icon:             'icon-file-zip',
                    color:            'teal',
                    outside_dropdown: true,
                    visible:          !this.readonly && this.isZip,
                    action:           () => this.zipSchemaVisible = !this.zipSchemaVisible,
                },
            ],
            sorting:            {
                column:    0,
                direction: 'asc',
            },
            columns:            [
                {
                    name:       'bulletin_name',
                    data:       'bulletin_name',
                    title:      'form.field.bulletin-name',
                    orderable:  false,
                    searchable: false,
                    type:       'processed',
                    processor:  row => this.prettifyBulletinName(row),
                },
                {
                    data:      'model_file',
                    name:      'model_file',
                    title:     'form.field.model-file',
                    type:      'custom-cell',
                    viewModel: PLATFORM.moduleName('modules/aggregates/selected-bulletins/custom-listing-cells/file/index'),
                    orderable: false,
                },
                {
                    data:      'is_test_uploaded',
                    name:      'is_test_uploaded',
                    title:     'form.field.is-test-uploaded',
                    type:      'custom-cell',
                    viewModel: PLATFORM.moduleName('modules/aggregates/selected-bulletins/custom-listing-cells/is-test-uploaded/index'),
                    orderable: false,
                },
                {
                    name:       'operator_name',
                    data:       'operator_name',
                    title:      'form.field.operator-name',
                    orderable:  false,
                    searchable: false,
                    type:       'custom-cell',
                    viewModel:  PLATFORM.moduleName('modules/aggregates/selected-bulletins/custom-listing-cells/operator-name/index'),
                },
            ],
        };
    }

    hideDatatable(hidden = true) {
        this.datatableHidden = hidden;
    }

    /**
     * Performs a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    search(criteria) {
        let records = this.records;

        if (criteria.search.value !== null && criteria.search.value !== '') {
            let regExp = new RegExp(criteria.search.value, 'gi');

            records = this.records.filter((record) => {

                return ('' + record.bulletin_id).match(regExp)
                    || ('' + record.bulletin_name).match(regExp)
                    || ('' + record.norm).match(regExp)
                    || ('' + record.test_code).match(regExp)
                    || ('' + record.bulletin_revision_id).match(regExp)
                    || ('' + record.norm_edition).match(regExp)
                    || ('' + record.revision_number).match(regExp)
                    || ('' + record.bulletin_model).match(regExp)
            });
        }

        return Promise.resolve({
            data:            records,
            recordsTotal:    this.records.length,
            recordsFiltered: records.length,
        });
    }

    /**
     * Opens the given modal
     *
     * @param params
     * @param modal
     */
    openModal(params, modal) {
        this.dialogService
            .open({ viewModel: modal, model: params })
            .whenClosed((response) => {

                if (!response.wasCancelled) {
                    this.modalJustClosed                  = true;
                    this.completeModel.selected_bulletins = this.records;

                    this.appContainer.eventAggregator.publish('datatable-must-be-reloaded', { listingId: this.listingId });
                }
            });
    }

    prettifyBulletinName(row) {
        let html = '<h5 class=\'mb-1\'>';
        html += row.bulletin_name;
        html += '</h5>';
        html += '<span>';
        html += row.norm;
        html += '&nbsp;&bull;&nbsp;';
        html += row.bulletin_model;
        html += '&nbsp;&bull;&nbsp;(R';
        html += row.revision_number;
        html += ')';
        html += '</span>';
        return html;
    }

}
