export class SelectedBulletinOperatorNameCustomListingCell {
    /**
     * Handles activate event
     *
     * @param model
     */
    activate(model) {
        this.column = model.column;
        this.row    = model.row;
    }
}
