import { DialogController }                from 'aurelia-dialog';
import { bindable, inject }                from 'aurelia-framework';
import { PLATFORM }                        from 'aurelia-pal';
import { BaseFormViewModel }               from 'base-form-view-model';
import { FormSchema }                      from 'modules/aggregates/selected-bulletins/form-schema';
import { SelectedBulletinFilesRepository } from 'modules/aggregates/selected-bulletins/services/files-repository';
import { AppContainer }                    from 'resources/services/app-container';

@inject(AppContainer, DialogController, FormSchema, SelectedBulletinFilesRepository)
export class EditSelectedBulletin extends BaseFormViewModel {

    formId = 'test-selected-bulletins-edition-form';

    @bindable alert;
    @bindable settings = {
        title: 'form.title.edit-record',
        size:  'modal-md',
    };

    /**
     *
     * @param appContainer
     * @param dialogController
     * @param formSchema
     * @param filesRepository
     */
    constructor(appContainer, dialogController, formSchema, filesRepository) {
        super(appContainer);

        this.dialogController = dialogController;
        this.formSchema       = formSchema;
        this.self             = this;
        this.filesRepository  = filesRepository;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/aggregates/selected-bulletins/form.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'test-selected-bulletins.manage',
            'test-selected-bulletins.edit',
        ]);
    }

    activate(params) {
        super.activate();

        this.records = params.records;
        this.row     = params.row;
        this.index   = params.index;

        this.model        = this.formSchema.model();
        this.initialModel = this.formSchema.model();

        this.model.assign(this.row);

        this.schema       = this.formSchema.schema(this);
        this.globalSchema = this.formSchema.globalSchema(this);
    }

    async submit() {
        this.alert = null;

        if (!this.allRequiredFieldsAreFilled()) {
            return Promise.resolve([]);
        }

        await this.updateRow();
        this.initialModel.assign(this.model);

        this.appContainer.notifier.successNotice(this.appContainer.i18n.tr('form.message.success.edition-succeeded'))

        this.dialogController.ok(this.row);
    }

    cancel() {
        if (this.model === this.initialModel)
            return this.dialogController.ok()

        return this.dialogController.cancel()
    }

    /**
     * Update row with new model data
     * @returns {Promise<*>}
     */
    async updateRow() {

        this.row.uploaded_file = this.model.uploaded_file;
        this.row.date          = this.model.date;
        this.row.operator_name = this.model.operator_name;

        this.records[this.index] = this.row;

        return this.row;
    }

    /**
     * Check in the front-end if all required fields are filled
     * @returns {boolean}
     */
    allRequiredFieldsAreFilled() {
        let message = '';

        if (!this.model.uploaded_file) {
            message += this.getDescriptive('form.field.file');
        }
        if (this.row.tests_quantity === 1 && !this.model.date) {
            message += this.getDescriptive('form.field.date');
        }
        if (!this.model.operator_name) {
            message += this.getDescriptive('form.field.operator-name');
        }

        if (message.length > 0) {
            this.alert = this.dangerAlertMessage(
                this.appContainer.i18n.tr('messages.action.failed'),
                message,
            );
        }

        return message.length === 0;

    }

    /**
     * Returns descriptive
     *
     * @param slug
     *
     * @returns String
     */
    getDescriptive(slug) {
        return '<li>' + this.appContainer.i18n.tr('form.field.frontend-mandatory-field', {
            field: this.appContainer.i18n.tr(slug),
        }) + '</li>';
    }

}
