import { inject }                   from 'aurelia-framework';
import { TestSelectedBulletinsZip } from 'modules/aggregates/models/test-selected-bulletins-zip';

@inject()
export class TestsQuantitySchema {

    /**
     * Model default values
     */
    modelDefaults = {};

    constructor() {
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {TestSelectedBulletinsZip}
     */
    model(selectedLot = null) {
        let model = new TestSelectedBulletinsZip();

        model.assign(this.modelDefaults);

        return model;
    }

    schema(viewModel, readonly = false) {

        this.tests_quantity = {
            type:            'text',
            key:             'tests_quantity',
            label:           'form.field.tests-quantity',
            size:            12,
            valueConverters: [
                { name: 'numbersOnly' },
            ],
            attributes:      {
                readonly: readonly,
            },
        };

        return [
            [this.tests_quantity],
        ];
    }
}
