import { inject }               from 'aurelia-framework';
import { TestSelectedBulletin } from 'modules/aggregates/models/test-selected-bulletin';

@inject()
export class FormSchema {

    /**
     * Model default values
     */
    modelDefaults = {};

    constructor() {
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {TestSelectedBulletin}
     */
    model(selectedLot = null) {
        let model = new TestSelectedBulletin();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     * @return {*}
     */
    schema(viewModel) {
        this.uploaded_file = {
            type:             'file-dropzone',
            key:              'uploaded_file',
            label:            'form.field.bulletin-file',
            url:              viewModel.filesRepository.uploadUri(),
            returnModel:      true,
            mockFile:         viewModel.model.uploaded_file,
            allowedMimeTypes: 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            size:             12,
        };

        this.date = {
            type:   'material-ui-date-picker',
            key:    'date',
            label:  'form.field.date',
            hidden: viewModel.row.tests_quantity > 1,
            size:   6,
        };

        this.operator_name = {
            type:  'text',
            key:   'operator_name',
            label: 'form.field.operator',
            size:  (viewModel.row.tests_quantity > 1) ? 12 : 6,
        };

        return [
            [this.uploaded_file, this.date, this.operator_name],
        ];
    }

    /**
     * Returns buttons schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    globalSchema(viewModel) {

        this.cancelButton = {
            type:       'button',
            label:      'form.button.cancel',
            action:     () => viewModel.cancel(),
            attributes: {
                class: 'btn btn-link',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.cancelButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
