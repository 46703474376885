import { BaseModel } from 'modules/core/models/base-model';

export class BaseAggregatesTest extends BaseModel {
    // Aggregates Test
    aggregates_test_id        = null;
    listing_revision_id       = null;
    material_type_id          = null;
    control_type_id           = null;
    part_zone_application_id  = null;
    layer_id                  = null;
    track_id                  = null;
    depth_quota               = null;
    axis_distance             = null;
    layer_thickness           = null;
    validated_by              = null;
    validated_at              = null;
    verified_inspection_by    = null;
    verified_inspection_at    = null;
    verified_concessionary_by = null;
    verified_concessionary_at = null;
    // Test Header
    test_header_id            = null;
    concession_id             = null;
    entity_id                 = null;
    team_id                   = null;
    highway_id                = null;
    intervention_type_id      = null;
    lot_intervention_id       = null;
    date                      = null;
    external_reference        = null;
    observations              = null;
}
