import { inject }                   from 'aurelia-framework';
import { TestSelectedBulletinsZip } from 'modules/aggregates/models/test-selected-bulletins-zip';

@inject()
export class AttachZipSchema {

    /**
     * Model default values
     */
    modelDefaults = {};

    constructor() {
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {TestSelectedBulletinsZip}
     */
    model(selectedLot = null) {
        let model = new TestSelectedBulletinsZip();

        model.assign(this.modelDefaults);

        return model;
    }

    schema(viewModel, readonly = false) {

        this.zip_file_id = {
            type:       'file-dropzone',
            key:        'zip_file_id',
            label:      'form.field.upload-bulletins-zip',
            url:        viewModel.zipFilesRepository.uploadUri(),
            mockFile:   viewModel.model.zip_file_id,
            size:       12,
            attributes: {
                readonly: readonly,
            },
        };

        return [
            [this.zip_file_id],
        ];
    }
}
