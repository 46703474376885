import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class TestAdditionalInfoRepository extends BaseRepository {
    baseUrl = 'test-headers';

    /**
     * Fetches TestHeader-related Additional Information
     *
     * @param relatableId
     * @param criteria
     *
     * @returns {*}
     */
    testAdditionalInfo(relatableId, criteria) {
        return this.httpClient.post(`${this.baseUrl}/${relatableId}/additional-info`, criteria);
    }

}
