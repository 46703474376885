import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class SelectedBulletinsRepository extends BaseRepository {
    baseUrl = 'test-selected-bulletins';

    /**
     * Retrieves template file IDs given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    getModels(criteria) {
        return this.httpClient.post(`${this.baseUrl}/get-models`, criteria);
    }
}
